// Action Types
import { skyviewActionTypes } from '../../contractor/skyview/store/skyview_s';
const nonImmutableAT = {
    'SV/setMapData': 'SV/setMapData',
    'DB/setMapData': 'DB/setMapData',
    'nonIM/reset': 'nonIM/reset',
}


export const setMapDataSV = (mapData) => ({
    type: nonImmutableAT['SV/setMapData'],
    mapData,
});

export const setMapDataDB = (mapData) => ({
    type: nonImmutableAT['DB/setMapData'],
    mapData,
});

export const resetNonIM = () => ({
    type: nonImmutableAT['nonIM/reset'],
});


const initState = {
    SVmapData: null,
    DBmapData: [],
};


const nonIMReducer = (state=initState, action) => {

    switch(action.type){

        case nonImmutableAT['SV/setMapData']:
            console.log('hit ', action);
            const stateSV = {...state, ...{}};
            const mapDataSV = action.mapData || [];

            stateSV.SVmapData = mapDataSV;

            return {...state, ...stateSV};

        case nonImmutableAT['DB/setMapData']:
            const stateDB = {...state, ...{}};
            const mapDataDB = action.mapData || [];

            stateDB.DBmapData = mapDataDB;

            return {...state, ...stateDB};

        case skyviewActionTypes['skyview/reset']:
        // case skyviewActionTypes['skyview/setJobName']:
        case nonImmutableAT['nonIM/reset']:
            console.log('initState ', initState);
            return initState;

        default:
            return state;
    }
};


export default nonIMReducer;
