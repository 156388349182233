import Immutable from 'immutable';

const equipmentResetLayerObj = Immutable.fromJS({
    selectedJobs: [{label: 'All', value: 'all'}],
    jobNames: [{label: 'All', value: 'all'}],
    selectedSegment: '',
    segmentFeatureCollection: {}
});

export default equipmentResetLayerObj;
