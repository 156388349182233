import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth0 } from "../shared/auth/react-auth0-spa";
import {
    IconButton,
    AppBar,
    Toolbar,
    Menu,
    MenuItem,
    Grid,
} from '@material-ui/core';
import { Menu as MenuIcon, ChevronRight, Settings } from '@material-ui/icons';

TopBarContainer.propTypes = {
    toggleSideBar: PropTypes.func.isRequired
}


function TopBarContainer(props){

    const { logout } = useAuth0();
    
    const { toggleSideBar } = props;
    
    // state
    const [anchorEl, setAnchorEl] = useState(null); // settings icon

    const { organizationID } = useSelector(state => ({
        organizationID: state.app.getIn(['organization', 'name'], 'noOrgID')
    }))
    
    // func
    const displaySettings = (event) => setAnchorEl(event.currentTarget);
    const hideSettings = () => setAnchorEl(null);

    const _logout = () => logout({ returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL });

    return (
        <div>
            <AppBar position="static">
                <Toolbar variant="dense">
                    <Grid item xs={1}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleSideBar}
                        >
                            <MenuIcon />
                            <ChevronRight style={{ marginLeft: -8 }} />
                        </IconButton>
                    </Grid>
                    <Grid 
                        item 
                        xs={11} 
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <IconButton onClick={displaySettings} style={{ color: "white" }}>
                            <Settings />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={hideSettings}
                        >
                            <MenuItem onClick={hideSettings}>
                                <Link 
                                    to={`/settings/threshold/${organizationID}`}
                                    style={{ color: "black", textDecoration: "none"}}
                                >    
                                    Settings
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={_logout}>Log Out</MenuItem>
                        </Menu>
                    </Grid>
                </Toolbar>
            </AppBar>
        </div>
    )
};


export default TopBarContainer;
