import { combineReducers } from 'redux';
// reducers
import app from './app_s';
import nonIM from './non_immutable_s';
import skyview from '../../contractor/skyview/store/skyview_s';
import equipment from '../../contractor/equipment/store/equipment_s';
import reports from '../../contractor/reports/store/reports_s';

export default combineReducers({
    app,
    skyview,
    equipment,
    nonIM,
    reports
});
