import React, { useState } from 'react';

import SideBarContainer from './SideBarContainer';
import TopBarContainer from './TopBarContainer';
import { Grid } from '@material-ui/core';


function NavBarContainer(props){

    const [sideBarOpen, setSideBarState] = useState(false);

    const toggleSideBar = () => setSideBarState(!sideBarOpen);

    return (
        <Grid item xs={12}>
            <TopBarContainer toggleSideBar={toggleSideBar} />
            <SideBarContainer toggleSideBar={toggleSideBar} sideBarOpen={sideBarOpen} />
        </Grid>
    )
}


export default NavBarContainer;
