import React from 'react';
import ReactDOM from 'react-dom';
import specRiteLogo from './images/logo-for-auth0.png';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import {Router, Route, Switch} from 'react-router-dom';
import RegisterContainer from './register/RegisterContainer';

import Root from './Root';

import { Auth0Provider } from "./shared/auth/react-auth0-spa";

import config from "./shared/configs/auth0_c.js";
import history from "./shared/history";

// Setting up Sentry
const RELEASE = process.env.REACT_APP_VERSION_RELEASE;
const ENVIRONMENT = process.env.REACT_APP_SENTRY_ENV;

if (process.env.NODE_ENV === 'production') {
    console.log = function(){};
    Sentry.init({
        dsn: 'https://4257496b73c44d539df29e39055bddec@sentry.io/1462207',
        release: RELEASE,
        environment: ENVIRONMENT,
        // maxBreadcrumbs: 10,
        integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })]
    })
}

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
    history.push(
        appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
};

const options = {
    theme: {
        logo: specRiteLogo,
    },
    languageDictionary: {
        title: "Spec-Rite"
    }
};

ReactDOM.render(
    <Auth0Provider
        domain={config.domain}
        audience={config.apiIdentifier}
        client_id={config.clientId}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        scope={config.scope}
        options={options}
        responseType={config.responseType}
    >
        <Router history={history}>
            <Switch>
                <Route
                    exact path='/register_user/:trialUser/:token'
                    component={RegisterContainer}
                />
                <Route path="/" component={Root} />
            </Switch>
        </Router>
    </Auth0Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
