import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk'
import { Route } from 'react-router-dom';
import { useAuth0 } from "./shared/auth/react-auth0-spa";

import history from "./shared/history";
import rootReducer from './shared/stores/root_s';
import _API from './shared/API';

import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './shared/styles/appStyles';
import LoginPage from './shared/auth/LoginPage';
import { catchBlockErrors } from './shared/utils/sentry';
import App from './App';
import Loading from './shared/components/SpinnerLoading';
import { SnackbarProvider } from 'notistack';
// Styles
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    // moves snackbar above intercom message
    anchorOriginBottomLeft: {
        top: -70
    },
});

const store = createStore(
    rootReducer,
    applyMiddleware(ReduxThunk)
);

// export the api class after we pass it dispatch
export const API = new _API(store.dispatch);


function Root(props) {
    const classes = useStyles();
    
    const { loading, isAuthenticated } = useAuth0();

        // Only way to add styling to the body tag
        // overscroll-behavior prevents scroll bounce at ends of content

    console.log('isAuthenticated', isAuthenticated);
    try {
        // if auth0 is loading
        if (loading) {
            return (<div><Loading /></div>);
        } else if (!isAuthenticated) {
            return <LoginPage />
        }
    } catch(error){
        catchBlockErrors({error, fileFunction: 'Root/second one'});
        return <LoginPage />
    };

    // only return the app once we are authenticated
    return (
        isAuthenticated && (
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider maxSnack={10} className={classes.anchorOriginBottomLeft}>
                        <Route
                            history={history}
                            component={App}
                            path="/:module?/:tool?/:organizationID?"
                        />
                    </SnackbarProvider>
                </ThemeProvider>
            </Provider>
        )
    )
}


export default Root;
