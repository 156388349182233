import Immutable, { List, Map } from 'immutable';
import { appActionTypes } from '../../../shared/stores/app_s';
// import { catchBlockErrors } from '../../../shared/utils/sentry';

// Layer Objects
import { 
    applicationLayerObj, 
    baseLayerObj,
    layerResetObject,
    retroLayerObj,
} from './layer_objects';
// Utils
import { 
    getDatesFromRange,
    sortObjectsByProperty,
    filterChangesResetState,
} from './utils'

// Action Types
export const skyviewActionTypes = {
    'skyview/setEquipmentIDAndName': 'skyview/setEquipmentIDAndName',
    'skyview/setJobName': 'skyview/setJobName',
    'skyview/setSegmentationType': 'skyview/setSegmentationType',
    'skyview/setSegmentationID': 'skyview/setSegmentationID',
    'skyview/setMapView': 'skyview/setMapView',
    'skyview/updateExtraLayers': 'skyview/updateExtraLayers',
    'skyview/setJobNames': 'skyview/setJobNames',
    'skyview/setEquipmentNames': 'skyview/setEquipmentNames',
    'skyview/loadingJobs': 'skyview/loadingJobs',
    'skyview/setSelectedLayer': 'skyview/setSelectedLayer',
    'skyview/loadingEquipmentNames':'skyview/loadingEquipmentNames', 
    'skyview/toggleLayersPanel':'skyview/toggleLayersPanel', 
    'skyview/toggleLOP':'skyview/toggleLOP', 
    'skyview/toggleAdvancedFilters':'skyview/toggleAdvancedFilters', 
    'skyview/toggleLayerVisibility': 'skyview/toggleLayerVisibility',
    'skyview/resetLayer': 'skyview/resetLayer',
    'skyview/addLayer': 'skyview/addLayer',
    'skyview/removeLayer': 'skyview/removeLayer',
    'skyview/setMapDataLoading': 'skyview/setMapDataLoading',
    'skyview/setFirstLoad': 'skyview/setFirstLoad',
    'skyview/setLayerFeatureCollection': 'skyview/setLayerFeatureCollection',
    'skyview/handleMapLayerClick': 'skyview/handleMapLayerClick',
    'skyview/setMapLayerIdAndListeners': 'skyview/setMapLayerIdAndListeners',
    'updateDateRange': 'updateDateRange',
    'clearLayerUpdates': 'clearLayerUpdates',
    'toggleLayerVisibility': 'toggleLayerVisibility',
    'dropSelectedSegment': 'dropSelectedSegment',
};


// Actions
// LOP

export const dropSelectedSegment = () => ({
    type: skyviewActionTypes['dropSelectedSegment'],
})

export const setMapLayerIdAndListeners = (mapLayerId, _mapLayerIndex, listeners) => ({
    type: skyviewActionTypes['skyview/setMapLayerIdAndListeners'],
    mapLayerId,
    _mapLayerIndex,
    listeners
})


export const handleMapLayerClick = (layerType, mapLayerIndex, data, featureIndex) => ({
    type: skyviewActionTypes['skyview/handleMapLayerClick'],
    layerType,
    mapLayerIndex,
    data,
    featureIndex
});

export const setLayerFeatureCollection = (featureCollection, layerIndex) => ({
    type: skyviewActionTypes['skyview/setLayerFeatureCollection'],
    featureCollection,
    layerIndex
});

export const setFirstLoad = (bool) => ({
    type: skyviewActionTypes['skyview/setFirstLoad'],
    bool
});

export const setMapDataLoading = (bool) => ({
    type: skyviewActionTypes['skyview/setMapDataLoading'],
    bool
});

export const removeLayer = (layerID) => ({
    type: skyviewActionTypes['skyview/removeLayer'],
    layerID
})

export const addLayer = (layerType) => ({
    type: skyviewActionTypes['skyview/addLayer'],
    layerType
})

export const resetLayer = (layerIndex) => ({
    type: skyviewActionTypes['skyview/resetLayer'],
    layerIndex
})

export const toggleLayerVisibility = (layerIndex) => ({
    type: skyviewActionTypes['skyview/toggleLayerVisibility'],
    layerIndex
})

export const toggleLOP = (bool) => ({
    type: skyviewActionTypes['skyview/toggleLOP'],
    bool
})

export const toggleAdvancedFilters = () => ({
    type: skyviewActionTypes['skyview/toggleAdvancedFilters'],
})

export const toggleLayersPanel = (bool) => ({
    type: skyviewActionTypes['skyview/toggleLayersPanel'],
    bool
})

export const setLoadingJobNames = (bool) => ({
    type: skyviewActionTypes['skyview/loadingJobs'],
    bool
})

export const setLoadingEquipmentNames = (bool) => ({
    type: skyviewActionTypes['skyview/loadingEquipmentNames'],
    bool
})

export const setJobNames = (jobNames, layerIndexForJobNames) => ({
    type: skyviewActionTypes['skyview/setJobNames'],
    jobNames,
    layerIndexForJobNames
})

export const setEquipmentNames = (equipmentNames, layerIndex) => ({
    type: skyviewActionTypes['skyview/setEquipmentNames'],
    equipmentNames,
    layerIndex
})

export const setSelectedLayer = (layerIndex) => ({
    type: skyviewActionTypes['skyview/setSelectedLayer'],
    layerIndex
});

export const updateExtraLayers = (layer) => ({
    type: skyviewActionTypes['skyview/updateExtraLayers'],
    layer
});


export const setMapView = (view) => ({
    type: skyviewActionTypes['skyview/setMapView'],
    view
});


export const setSegmentationID = (id) => ({
    type: skyviewActionTypes['skyview/setSegmentationID'],
    id
});

export const setEquipmentIDAndName = (equipmentObj) => ({
    type: skyviewActionTypes['skyview/setEquipmentIDAndName'],
    equipmentObj 
});

export const setJobName = (name) => ({
    type: skyviewActionTypes['skyview/setJobName'],
    name
});

export const setSegmentationType = (segType) => ({    
    type: skyviewActionTypes['skyview/setSegmentationType'],
    segType
});

export const resetSkyviewStore = () => ({
    type: skyviewActionTypes['skyview/reset']
});

export const updateDateRange = ({range, dates=[], layerIndexDR}) => ({
    type: skyviewActionTypes['updateDateRange'],
    range,
    dates,
    layerIndexDR
});

export const clearLayerUpdates = () => ({
    type: skyviewActionTypes['clearLayerUpdates'],
});



const layerTypes = {
    application: baseLayerObj.merge(applicationLayerObj),
    retro: baseLayerObj.merge(retroLayerObj),
}

let layerColors = List(['#08bf8b', '#99187f', '#0a61d1', '#00a4c4', '#6b25b1']);
const initialState = Immutable.fromJS({
    advancedFiltersOpen: false,
    layerPanelOpen: true,
    selectedLayer: 0, // index of selected layer
    loadingJobNames: false,
    loadingEquipmentNames: false,
    LOPopen: true,
    firstLoad: true, // indicates if this is the first time all layer data is being fetched
    mapDataLoading: false, 
    view: 'dark-v10',
    commentsLayer: false,
    mileMarkersLayer: false,
    layerUpdates: null,
    // either null or an object
    // {
    //      action: 'update',
    //      data: <index><array of layers><mapLayerId>,
    // }

    layers: [],
    hasViewChange: false,

});


const skyviewReducer = (state=initialState, action) => {

    const selectedLayer = state.get('selectedLayer', 0);

    switch(action.type){

            // LOP
        case appActionTypes['org/setOrg']:
            const _organizationObj = action.org;

            // const _orgLayerTypes = List(['application', 'retro']);
            const _orgLayerTypes = List(['application']);

            const _initialLayers = _orgLayerTypes.map(_layerType => {

                const idColor = layerColors.get(0);
                layerColors = layerColors.delete(0);
                let _layerObj = layerTypes[_layerType];
                _layerObj = _layerObj.set('idColor', idColor);
                return _layerObj;
            });

            return state.set('layers', _initialLayers);


        case skyviewActionTypes['dropSelectedSegment']:
            return state
                .setIn(['layers', selectedLayer, 'selectedSegment'], '')
                .setIn(['layers', selectedLayer, 'segmentFeatureCollection'], {});


        case skyviewActionTypes['clearLayerUpdates']:
            return state.set('layerUpdates', null);


        case skyviewActionTypes['skyview/setMapLayerIdAndListeners']:
            const { mapLayerId, _mapLayerIndex, listeners } = action;
            const _mapLayerIdState = state
                .setIn(['layers', _mapLayerIndex, 'mapLayerId'], mapLayerId)
                .setIn(['layers', _mapLayerIndex, 'listeners'], listeners);


            return state.merge(_mapLayerIdState);


        case skyviewActionTypes['skyview/handleMapLayerClick']:
            const { layerType, mapLayerIndex, data, featureIndex } = action;

            const isDataLoading = state.get('mapDataLoading', false);
            if (!isDataLoading){
                let _mapClickState = state.set('mapDataLoading', true);

                if ( selectedLayer !== mapLayerIndex ) {
                    _mapClickState = _mapClickState.set('selectedLayer', mapLayerIndex);
                }

                let _mapLayerClickState = _mapClickState.getIn(
                    ['layers', mapLayerIndex], false
                );

                // there should be a situtaiton where this is false but doesn't hurt
                // to check
                if (_mapLayerClickState) {
                    // break these out into utility funcitons
                    if ( layerType === 'overview' ){
                        _mapLayerClickState = _mapLayerClickState.setIn(
                            ['selectedJobs', 0], data
                        );
                        _mapLayerClickState = _mapLayerClickState.set(
                            'selectedSegment', ''
                        );
                        _mapClickState = _mapClickState.set('layerUpdates', Map({
                            type: 'update',
                            data: mapLayerIndex
                        }))

                    } else if ( layerType === 'segment' ) {
                        const _segmentIdMapClick = data.get('value', '');;
                        // feature collections are not immutable;
                        const _featureCollection = _mapLayerClickState.get(
                            'featureCollection', false
                        );
                        if (_featureCollection) {
                            const _selectedSegmentFeature = _featureCollection
                                .features[featureIndex];
                            _mapLayerClickState = _mapLayerClickState.set(
                                'segmentFeatureCollection', {
                                    // 'featureCollection', {
                                    type: 'FeatureCollection',
                                    features: [_selectedSegmentFeature]
                                }
                            );

                        }
                        _mapClickState = _mapClickState
                            .set('layerUpdates', Map({
                                type: 'segmentSelect',
                                data: mapLayerIndex
                            }))
                            .set('mapDataLoading', false);

                        _mapLayerClickState = _mapLayerClickState.set(
                            'selectedSegment', _segmentIdMapClick
                        );
                    }
                    _mapClickState = _mapClickState.setIn(
                        ['layers', mapLayerIndex], _mapLayerClickState
                    );

                }
                return state.merge(_mapClickState);
            } else return state;


        case skyviewActionTypes['skyview/setLayerFeatureCollection']:

            // featureCollection is not immutable and we want it that way
            // to large to for us to be turning it back and forth between being
            // immutable
            //

            let { featureCollection, layerIndex } = action;


            const isOverview = state.getIn(['layers', layerIndex, 'selectedJobs', 0, 'value'], 'all');

            const _lineColor =  state.getIn(['layers', layerIndex, 'idColor'], 'blue');
            if (isOverview === 'all') {
                if (_lineColor 
                    && featureCollection 
                    && featureCollection.features 
                    && featureCollection.features.length > 0
                ) {
                    featureCollection.features.forEach((feature, index) => {
                        featureCollection.features[index].properties.linecolor = _lineColor;
                    });
                    console.log('featureCollection', featureCollection);
                    // layerColors = layerColors.delete(0);
                }
            }


            let _stateFC = state
                .setIn(
                    ['layers', layerIndex, 'featureCollection'], featureCollection
                )
                .set('layerUpdates', Map({ type: 'updateFeatureCollection' }))
                .set('mapDataLoading', false);

            if (isOverview === 'all') {
                _stateFC = _stateFC.setIn(['layers', layerIndex, 'idColor'], _lineColor);
            }

            return state.merge(_stateFC);


        case skyviewActionTypes['skyview/setFirstLoad']:
            const bool = action.bool
            let _firstLoadState =  state.set('firstLoad', bool);
            return state.merge(_firstLoadState);


        case skyviewActionTypes['skyview/setMapDataLoading']:
            const  _mapDataLoading = action.bool;
            return state.set('mapDataLoading', _mapDataLoading);


        case skyviewActionTypes['skyview/removeLayer']:
            const _removeLayerID = action.layerID;

            let _removedLayerState = state.get('layers', List([]));
            let _updateLayersState = state;

            if (state.get('layers').size > 1 ){
                const layerIdsAndListeners = _removedLayerState.map(layer => {
                    return Map({
                        id: layer.get('mapLayerId'),
                        listeners: layer.get('listeners', List([]))
                    })
                });
                const _colorForArray = _removedLayerState.getIn([_removeLayerID, 'idColor'], '');
                if (_colorForArray) layerColors = layerColors.push(_colorForArray);
                _removedLayerState = _removedLayerState.delete(_removeLayerID);
                _updateLayersState = _updateLayersState.set('layers', _removedLayerState);
                _updateLayersState = _updateLayersState.set(
                    'mapDataLoading', true
                );
                _updateLayersState = _updateLayersState.set('layerUpdates', Map({
                    type: 'delete',
                    data: layerIdsAndListeners,
                }));

                if (_removeLayerID === selectedLayer) {
                    _updateLayersState = _updateLayersState.set('selectedLayer', 0);
                } else if (_removeLayerID < selectedLayer){
                    _updateLayersState = _updateLayersState.set(
                        'selectedLayer', selectedLayer - 1
                    );
                };
            }

            return state.merge(_updateLayersState);


        case skyviewActionTypes['skyview/addLayer']:
            const _newLayerType = action.layerType;
            let _newLayerObj = layerTypes[_newLayerType];
            const idColor = layerColors.get(0);
            layerColors = layerColors.delete(0);
            _newLayerObj = _newLayerObj.set('idColor', idColor);
            console.log('new', _newLayerObj);



            let _newLayersState = state.get('layers', List([]));
            let _updatedSelectedLayer = selectedLayer;
            if (_newLayersState.size < 5) {
                _newLayersState = _newLayersState.push(_newLayerObj);
                _updatedSelectedLayer = _newLayersState.size - 1;
                _newLayersState = _newLayersState.set(
                    'newLayerToAdd', _newLayersState.size - 1
                );
            }

            let _addedLayerState = state.set('layers', _newLayersState);
            _addedLayerState = _addedLayerState.set(
                'selectedLayer', _updatedSelectedLayer
            )
                .set('layerUpdates', Map({
                    type: 'add',
                    data: _updatedSelectedLayer
                }))
                .set('mapDataLoading', true)

            return state.merge(_addedLayerState);


        case skyviewActionTypes['skyview/resetLayer']:

            const _resetIndex = action.layerIndex;
            const _resetPath = ['layers', _resetIndex];

            let _resetLayer = state.getIn(_resetPath);
            _resetLayer = _resetLayer.merge(layerResetObject);

            const _resetLayerState = state
                .set('mapDataLoading', true)
                .set('selectedLayer', _resetIndex)
                .set('layerUpdates', Map({
                    type: 'reset',
                    data: _resetIndex
                }))
                .setIn(_resetPath, _resetLayer);

            return state.merge(_resetLayerState);


        case skyviewActionTypes['skyview/toggleLayerVisibility']:
            const _layerVisibilityIndex = action.layerIndex;
            const _visibilityPath = ['layers', _layerVisibilityIndex, 'visibility'];
            const _currentLayerVisibility = state.getIn(_visibilityPath, false);
            const _layerMapId = state.getIn(['layers', _layerVisibilityIndex, 'mapLayerId'], false);

            let _visibilityState = state
                .setIn(_visibilityPath, !_currentLayerVisibility)
                .set('layerUpdates', Map({
                    type: 'visibility',
                    data: _layerMapId,
                    visibilityValue: !_currentLayerVisibility
                }))

            return state.merge(_visibilityState);


        case skyviewActionTypes['skyview/toggleAdvancedFilters']:
            const _advancedFiltersOpen = state.get('advancedFiltersOpen');;
            return state.set('advancedFiltersOpen', !_advancedFiltersOpen);


        case skyviewActionTypes['skyview/toggleLOP']:
            const _LOPopen = action.bool;
            return state.set('LOPopen', _LOPopen);


        case skyviewActionTypes['skyview/toggleLayersPanel']:
            const layersPanelBool = action.bool;
            return state.set('layerPanelOpen', layersPanelBool);


        case skyviewActionTypes['skyview/loadingJobs']:
            const _loadingJobNames = action.bool;
            return state.set('loadingJobNames', _loadingJobNames);


        case skyviewActionTypes['skyview/loadingEquipmentNames']:
            const _loadingEquipmentNames = action.bool;
            return state.set('loadingEquipmentNames', _loadingEquipmentNames);


        case skyviewActionTypes['skyview/setEquipmentNames']:
            const _equipmentNames = action.equipmentNames;
            // sort the array by whether or not the equipment is available
            const _equipmentNamesSorted = sortObjectsByProperty(_equipmentNames, 'available');
            // create the object for the dropdown menu
            let equipmentNamesSorted = _equipmentNamesSorted.map(equipment => ({
                value: equipment.get("id", "No Name"),
                label: equipment.get("name", "No Name"),
                isDisabled: !equipment.get("available", true),
            }));

            // add the all option 
            equipmentNamesSorted = equipmentNamesSorted.unshift({
                value: 'all',
                label: 'All',
                isDisabled: false
            });

            const equipmentNamesState = state.setIn(
                ['layers', selectedLayer, 'equipmentNames'], equipmentNamesSorted
            );

            return state.merge(equipmentNamesState);


        case skyviewActionTypes['skyview/setJobNames']:
            const { jobNames, layerIndexForJobNames} = action;
            const _jobNamesSorted = sortObjectsByProperty(jobNames, 'available');

            let jobNamesSorted = _jobNamesSorted.map(job => ({
                value: job.get("name", "No Name"),
                label: job.get("name", "No Name"),
                isDisabled: !job.get("available", true),
            }));

            jobNamesSorted = jobNamesSorted.unshift({
                value: 'all',
                label: 'All',
                isDisabled: false
            });

            let jobNamesState = state.setIn(
                ['layers', layerIndexForJobNames, 'jobNames'], jobNamesSorted
            );


            return state.merge(jobNamesState);


        case skyviewActionTypes['skyview/setSelectedLayer']:
            const _newLayerIndex = action.layerIndex || 0;
            let _selectedLayerState = state.set('selectedLayer', _newLayerIndex);
            return state.merge(_selectedLayerState);


        case skyviewActionTypes['updateDateRange']:
            const { range, dates, layerIndexDR } = action;
            const  {startDate, endDate} = getDatesFromRange(range, dates);

            let _dateRangeState = state.set('dateRangeOption', range);
            // when date changes set changes and update state;
            _dateRangeState = _dateRangeState
                .setIn(
                    ['layers', selectedLayer, 'dateRangeOption'], range
                )
                .setIn(
                    ['layers', selectedLayer, 'dateRange', 0], startDate
                )
                .setIn(
                    ['layers', selectedLayer, 'dateRange', 1], endDate
                );

            const _dateRangeUpdateObj = Map({
                type: 'update',
                layer: layerIndexDR,

            })
            const _dateRangeLayers = _dateRangeState.get('layers', List([]));
            const _dateRangeUpdatedStateObj = filterChangesResetState(
                'date',
                _dateRangeUpdateObj,
                selectedLayer,
                _dateRangeLayers,
            );

            // reset all job, equipment, segement related 
            _dateRangeState = _dateRangeState.merge(_dateRangeUpdatedStateObj);

            return state.merge(_dateRangeState);


        case skyviewActionTypes['skyview/setEquipmentIDAndName']:

            const equipmentName = action.equipmentObj;
            // set changes then merge in equipment reset obj
            let _equipmentState = state.setIn(
                ['layers', selectedLayer, 'selectedEquipment', 0], equipmentName
            )
            const _equipmentUpdateObj = Map({ type: 'update', layer: selectedLayer })
            const _equipmentLayers = _equipmentState.get('layers', List([]));
            const _equipmentUpdatedState = filterChangesResetState(
                'equipment',
                _equipmentUpdateObj,
                selectedLayer,
                _equipmentLayers
            );

            _equipmentState = _equipmentState.merge(_equipmentUpdatedState);

            return state.merge(_equipmentState);


        case skyviewActionTypes['skyview/setJobName']:
            const name = action.name || '';

            let jobNameState = state.setIn(
                ['layers', selectedLayer, 'selectedJobs', 0], name
            );
            const _jobUpdateObj = Map({ type: 'update', layer: selectedLayer })
            const _jobLayers = jobNameState.get('layers', List([]));
            const _jobUpdatedState = filterChangesResetState(
                'job',
                _jobUpdateObj,
                selectedLayer,
                _jobLayers
            );

            jobNameState = jobNameState.merge(_jobUpdatedState);

            return state.merge(jobNameState);


        case skyviewActionTypes['skyview/setSegmentationType']:
            const type = action.segType;
            let _segTypeState = state
                .setIn(
                    ['layers', selectedLayer, 'advancedFilters', 'segmentation'], type
                )
                .setIn(
                    ['layers', selectedLayer, 'selectedSegment'], ''
                )
                .setIn(
                    ['layers', selectedLayer, 'segmentFeatureCollection'], Map({})
                )
                .set('layerUpdates', Map({
                    type: 'update',
                    data: selectedLayer,
                }))
                .set('mapDataLoading', true);

            return state.merge(_segTypeState);


        case skyviewActionTypes['skyview/setMapView']:

            const { view } = action;
            let _updatedMapViewState = state.set('view', view)

            return state.merge(_updatedMapViewState);


        case skyviewActionTypes['skyview/setSegmentationID']:
            const _segID = action.id.get('value', '');

            const _segIDState =  state
                .setIn(
                    ['layers', selectedLayer, 'selectedSegment'], _segID
                )
                .set('layerUpdates', Map({
                    type: 'update',
                    data: selectedLayer,
                }));

            return state.merge(_segIDState);



        case skyviewActionTypes['skyview/updateExtraLayers']:
            const extraLayerKey = action.layer;
            let currentLayerState = state.get(extraLayerKey, false);
            const _mapExtraLayers = state.set(extraLayerKey, !currentLayerState);

            return state.merge(_mapExtraLayers);


        default:
            return state;
    }
}


export default skyviewReducer;
