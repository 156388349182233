import { catchBlockErrors } from '../../../../shared/utils/sentry';

const _today = new Date();
const _priorDate = new Date().setDate(_today.getDate()-30);

export const todaysDate = _today.toISOString().split('T')[0]
export const priorDate = new Date(_priorDate).toISOString().split('T')[0];

export const getDatesFromRange = (range, dates) => {
    try {
        const todayDateObj = new Date();
        let endDate = todayDateObj.toISOString().split('T')[0];
        let startDate = '';

        if (Number.isInteger(parseInt(range))) {
            const startDateObj = new Date().setDate(todayDateObj.getDate()-range);
            startDate = new Date(startDateObj).toISOString().split('T')[0];

        } else if (range === 'ytd') {
            const year = todayDateObj.getFullYear();
            startDate = new Date(year, 0, 1).toISOString().split('T')[0];
        } else if (range === 'wtd') {
            const lastSunday = todayDateObj.setDate(todayDateObj.getDate() - todayDateObj.getDay());
            startDate = new Date(lastSunday).toISOString().split('T')[0];
        } else if (range === 'custom') {
            startDate = dates[0];
            endDate = dates[1];
        };

        return {startDate, endDate};
    } catch(error){
        catchBlockErrors({error, fileFunction: 'skyview_s/getDatesFromRange'})
    }
};
