import { createMuiTheme } from "@material-ui/core";
// Original module with no default theme
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    root: {
        background: props => props.color,
        // overflow: 'auto',
    }

});

// take theme and pass it to makeStyles inside a component
// theme is passed via a Material-UI React Context provider in Root.js
export const theme = createMuiTheme({
    // have to use given keys associated with theme object
    // i.e. palette, overrides, breakpoints, etc.
    palette: {
        primary: {
            main: "#CF2031"
        }, 

    },
    // Pass in any global overrides for specific Material-UI components
    overrides: {
        MuiButton: {
            label: {
                // Only capitalizes first letter of word; overrides capitalizing all letters
                textTransform: 'capitalize' 
            }
        },
    },
    typography: {
        fontFamily: [
            'Montserrat',
            'Roboto',
            'Helevetica',
            'sans-serif'
        ],
    }
});

const appStyle = theme => ({
    // Used for centering Spinner/Loading indicator
    centerInParentDiv: {
        position: "absolute",
        width: "95%",
        height: "95%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        zIndex: 999,
    }
});

export default appStyle;
