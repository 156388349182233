import Immutable from 'immutable';
import { todaysDate, priorDate } from '../utils'


// this should work for both application and retro 
const layerResetObject = Immutable.fromJS({
    dateRange: [priorDate, todaysDate],
    dateRangeOption: '30',
    advancedFilters: {
        segmentation: 'pattern',
    },
    selectedJobs: [
        {
            value: 'all',
            label: 'All',
        }
    ],
    selectedEquipment: [
        {
            value: 'all',
            label: 'All',
        }
    ],
    jobNames: [
        { 
            value: 'all',
            label: 'All',
        }
    ],
    equipmentNames: [
        { 
            value: 'all',
            label: 'All',
        }
    ],
    selectedSegment: '',
    featureCollection: null,
    segmentFeatureCollection : {},
})

export default layerResetObject;
